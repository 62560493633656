import React from 'react';

// Define interface for component props/api:
export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void
  onDrag?: () => void
  onDragIn?: () => void
  onDragOut?: () => void
  onDrop?: () => void
  onFilesDrop?: (files: File[]) => void
}

export const DropZone = React.memo(
  (props: React.PropsWithChildren<DropZoneProps>) => {
    const {
      onDragStateChange, // passed in from FileUploader
      onFilesDrop,       // passed in from FileUploader
      onDrag,
      onDragIn,
      onDragOut,
      onDrop,
    } = props

    const [isDragActive, setIsDragActive] = React.useState(false)
    const dropZoneRef = React.useRef<null | HTMLDivElement>(null)

    
    const handleDragIn = React.useCallback((event: any) => {
      event.preventDefault() // cancel whatever default action this event has
      event.stopPropagation() // prevent the parent element from executing a handler for this event
      onDragIn?.()

      if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setIsDragActive(true)
      }
    }, [onDragIn])

    const handleDragOut = React.useCallback((event: any) => {
      event.preventDefault()
      event.stopPropagation()
      onDragOut?.()

      setIsDragActive(false)
    }, [onDragOut])

    const handleDrag = React.useCallback((event: any) => {
      event.preventDefault()
      event.stopPropagation()
      onDrag?.()

      if (!isDragActive) {
        setIsDragActive(true)
      }

    }, [isDragActive, onDrag])

    const handleDrop = React.useCallback((event: any) => {
      event.preventDefault()
      event.stopPropagation()

      setIsDragActive(false)
      onDrop?.()

      if (event.dataTransfer.files && event.dataTransfer.files.length === 1) {
        
        const filesToUpload = []

        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          filesToUpload.push(event.dataTransfer.files.item(i))
        }

        // invoke any optional method passed as onFilesDrop(), passing array
        // of files as an argument
        onFilesDrop?.(filesToUpload)

        // clear transfer data to prepare dropzone for another use
        event.dataTransfer.clearData()
      }
    }, [onDrop, onFilesDrop])


    // observe active state and emit changes
    React.useEffect(() => {
      onDragStateChange?.(isDragActive)
      // eslint-disable-next-line
    }, [isDragActive])

    // attach event listeners to dropzone on mount (when the DropZone component renders)
    React.useEffect(() => {
      const tempZoneRef = dropZoneRef?.current
      if (tempZoneRef) {
        tempZoneRef.addEventListener('dragenter', handleDragIn)
        tempZoneRef.addEventListener('dragleave', handleDragOut)
        tempZoneRef.addEventListener('dragover', handleDrag)
        tempZoneRef.addEventListener('drop', handleDrop)
      }

      // remove listeners from dropzone on unmount
      return () => {
        tempZoneRef?.removeEventListener('dragenter', handleDragIn)
        tempZoneRef?.removeEventListener('dragleave', handleDragOut)
        tempZoneRef?.removeEventListener('dragover', handleDrag)
        tempZoneRef?.removeEventListener('drop', handleDrop)
      }
      // eslint-disable-next-line
    }, [])

    // render <div> with ref and children
    return <div ref={dropZoneRef}>{props.children}</div>
  }
)

DropZone.displayName = 'DropZone'