import "./Header.css";

export function Header() {
  return (
    <div className="header-container">
      <h1 className="title">DUMPIT.GG</h1>
      <h2 className="subtitle">UPLOAD AND SHARE</h2>
    </div>
  );
}
