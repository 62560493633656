import React from "react";
import "./DropZone.css";
import { ReactComponent as UploadImg } from "../../assets/DocumentArrowUp.svg";

export interface DropZoneFileSelectProps {
  inputFile: React.RefObject<HTMLInputElement>;
}

export const DropZoneFileSelect = React.memo(
  (props: React.PropsWithChildren<DropZoneFileSelectProps>) => (
    <div onClick={() => props.inputFile.current?.click()}>
      <UploadImg width="100" height="100" stroke="#757575" />
      <div className="subtext1">
        Drag your file or <span className="subtext1a">click here</span>
      </div>
      <div className="subtext2">Max file size 200 MB</div>
    </div>
  )
);
