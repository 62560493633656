import { ErrorMessages } from "../constants/ErrorMessages";

class UnsupportedFileSize extends Error {
  constructor(message?: string) {
    super(
      message && message.trim() !== ""
        ? message
        : ErrorMessages.UNSUPPORTED_FILE_SIZE
    );
    this.name = "UnsupportedFileSize";
    this.stack = (<any>new Error()).stack;
  }
}

export default UnsupportedFileSize;
