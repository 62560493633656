import { ErrorMessages } from "../constants/ErrorMessages";

class UnsupportedFileType extends Error {
  constructor(message?: string) {
    super(
      message && message.trim() !== ""
        ? message
        : ErrorMessages.UNSUPPORTED_FILE_TYPE
    );
    this.name = "UnsupportedFileType";
    this.stack = (<any>new Error()).stack;
  }
}

export default UnsupportedFileType;
