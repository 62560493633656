export function convertUnits(fileSize: number) {
  const units = ["bytes", "KB", "MB", "GB"];
  let size = fileSize;

  for (let i = 0; i < units.length; i++) {
    if (size < 1000) {
      return `File size ${size} ${units[i]}`;
    }
    size = Math.round(size / 1000);
  }
}
