import React, { Dispatch, SetStateAction, createContext } from 'react';

interface UploadProgressContextProps {
  uploadProgress: string;
  setUploadProgress: Dispatch<SetStateAction<string>>;
}

const defaultState: UploadProgressContextProps = {
  uploadProgress: "",
  setUploadProgress: () => {},
};

const UploadProgressContext = createContext<UploadProgressContextProps>(defaultState);

export default UploadProgressContext;
